import { GoHome, GoBell } from "react-icons/go";
import { TbCategoryMinus } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";
import {
  MdOutlineScreenshotMonitor,
  MdOutlineEventNote,
  MdStars,
  MdOutlineEventAvailable,
  MdMicNone,
} from "react-icons/md";
import { GiWhistle } from "react-icons/gi";
import { PiUsersThree } from "react-icons/pi";

export const organizerLinks = [
  {
    label: "الرئيسية",
    path: "/",
    icon: <GoHome />,
  },
  {
    label: "تصنيفات تصنيف العمل",
    path: `/categories?parent_id=${process.env.REACT_APP_JOB_CLASS_CATEGORY_ID}`,
    icon: <TbCategoryMinus />,
  },
  {
    label: "تصنيفات صفة العمل",
    path: `/categories?parent_id=${process.env.REACT_APP_JOB_DESC_CATEGORY_ID}`,
    icon: <TbCategoryMinus />,
  },
  {
    label: "الاحداث",
    path: "/events",
    icon: <MdOutlineEventNote />,
  },
  {
    label: "المتحدثون",
    path: "/speakers",
    icon: <MdMicNone />,
  },
  {
    label: "الحكام",
    path: "/judges",
    icon: <GiWhistle />,
  },
  {
    label: "الموظفون",
    path: "/staff",
    icon: <PiUsersThree />,
  },
  {
    label: "بانرز",
    path: "/banners",
    icon: <MdOutlineScreenshotMonitor />,
  },
  {
    label: "الرعاة",
    path: "/sponsors",
    icon: <MdStars />,
  },
  {
    label: "الاشعارات",
    path: "/notifications",
    icon: <GoBell />,
  },
  {
    label: "الضبط",
    path: "/settings",
    icon: <FiSettings />,
  },
];

export const exhibitorLinks = [
  {
    label: "المعارض المشارك بها",
    path: "/exhibitor/events",
    icon: <MdOutlineEventNote />,
  },
  {
    label: "المعارض المتاحة",
    path: "/exhibitor/events/available",
    icon: <MdOutlineEventAvailable />,
  },
  {
    label: "الموظفون",
    path: "/employees/all",
    icon: <MdOutlineEventAvailable />,
  },
];
